import React from 'react'
import PropTypes from 'prop-types'
import * as styles from "../components/index.module.css"


function Test2(props) {
  return (
    <>
    
    <div className={styles.flexBox}> 
    <img src={props.img} alt="why is it not working" className={styles.gridimg}/>
    <div className={styles.gridItem}>
    <cardtitle>{props.name}</cardtitle>
    <description>{props.description}</description>
    <div className={styles.gridContainer}>
    <a href= {props.link}>
    <button>{props.button}</button></a>
    {props.button2 &&
    <a href= {props.link2}>
    <button>{props.button2}</button>
    </a>
    }
    </div>
    </div>
    </div>
    </>
  )
}

Test2.propTypes = {}

export default Test2
