import * as React from "react"
import Seo from "../components/seo"
import * as styles from "../components/index.module.css"
import {
  Divider
} from '@chakra-ui/react'
import Header from "../components/header"
import { motion as m } from "framer-motion";
import Test2 from "../components/Projectcard"
// import Testcard from "/Users/anulkhagvasuren/gatsby-p/src/components/Testcard.js"
const projects = [
  {
  name : "California's Policy on Electric Vehicles",
  description : "A poster explaining California's policy on electric vehicles, policy analysis. Notebook walkthrough explaining some data analysis and visualizations.",
  img : "https://images.unsplash.com/photo-1593941707882-a5bba14938c7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1772&q=80",
  hashtags : "#ev #policy #california #poster",
  link2: "https://drive.google.com/file/d/1hJxZ-cx2PYRE6GkRFfVA23cGJUZ7uIRR/view?usp=sharing",
  alt:"why is it not working",
  used : "Python, Pandas, Matplotlib, Seaborn, DeepNote",
  button2 : "Poster",
  button: "Video",
  link: "https://www.youtube.com/watch?v=0mGXEUSIk9c&t=4s"
},
{
  name : "2D Maze Game",
      description : "A tile based game of a maze, where the player collects points in a given amount of time. The player can move around the maze using the arrow keys, and it has some other fun features. Used graph data structures and Prim's algorithm to generate the maze.",
      notes : "",
      img : "https://www.shutterstock.com/image-vector/famous-old-video-game-scene-600w-1771175930.jpg",
      hashtags : "#mazegame #graph #prim",
      used : "Java",
      alt:"why is it not working",
      button: "Video",
      link: "https://youtu.be/XDIuuBxQiqY",
      button2: "Github",
      link2: "https://github.com/anu-lkh/supreme-broccoli/tree/main/proj3%20copy"
}
]
const SecondPage = () => (
  <>
  <Header/>
  <div
        style={{
          margin: `0 auto`,
          maxWidth: `var(--size-content)`,
          padding: `var(--size-gutter)`,
          backgroundColor: '#fcf6f5ff',
          borderRadius:"80px" ,
          boxShadow:'md'
        }}
      >
  <m.div  initial={{ y: 100, opacity: 0 }}
  animate={{ y:0,opacity: 1 }}
  transition={{ duration: 0.75, ease: "easeOut" }}>
   <div className={styles.textCenter}>
   CURRENTLY EDITING, PLEASE COME BACK LATER 🙏
      </div>
      <br/><br/>
    <Divider orientation='horizontal' />
    <div>
      {projects.map((project) => {
        return <Test2 name={project.name} description={project.description} img={project.img} hashtags={project.hashtags} used={project.used} alt={project.alt} button={project.button} link={project.link} button2={project.button2} link2={project.link2}/>
      })}
    </div>
  </m.div>
  </div>
  </>
)

export const Head = () => <Seo title="Page two" />

export default SecondPage
